<template>
    <div class="audit">
        <rxNavBar title="清退工单" androidOrIOSFlag="true"></rxNavBar>
        <div class="head">
            <div class="search">
                <div class="search-icon" @click="goSearch"></div>
                <input type="text" placeholder="房源地址/租客姓名/租客电话" v-model="inputValue" @keyup.enter="goSearch">
            </div>
        

        </div>
        <div class="auditDiv">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh" >
            <van-list v-model="loading" :finished="finished" :finished-text="isEmptyFlag ? '' : '已经到底了' " @load="this.onLoad">
                <div class="stateList" v-for="(item,index) in orders"   :key="index" >
                    <div>
                        <!--                循环列表状态 -->
                        <div class="stateUnapproval">
                            待处理
                        </div>
                        
                        <!--                循环列表头-->
                        <div class="headInfo" >
                            {{item.houseAddress}}
							<!-- <span class="position-span-right">{{item.roomDetailedAddress}}</span> -->
							<div @click="showMobile1(item.mobile)" class="tel_IMg" >
							    <img class="tel" src="../../assets/images/tel.png">
							</div>
                        </div>
                        <!--                循环列表详细信息-->
                        <div class="listDetail" >
                            <div class="proposerDiv">租客姓名</div>
                            <!--                    第一条竖线-->
                            <div class="rule1"></div>
                            <div class="proposerDiv">欠款金额</div>
                            <!--                    第二条竖线-->
                            <div class="rule2"></div>
                            <div class="proposerDiv">逾期天数</div>
                            <!--                    获取申请人的信息-->
                            <div class="proposerValueDiv">{{item.userName}}</div>
                            <!--                    获取房源编号的信息-->
                            <div class="budgetValueDiv">{{item.overdueAmount}}元</div>
                            <!--                    获取费用承担的信息-->
                            <div class="remouldValueDiv">{{item.overdueTime}}天</div>
                        </div>
                        <!--                分割线-->
                        <div class="rule3" v-if="item.typesOfRetirement != ''"></div>
                        <div class="apply-result" v-if="item.typesOfRetirement != ''">
                            <span class="createMethodsDiv">欠款原因 : </span>
                            <span class="createTimeDiv">{{item.typesOfRetirement}}</span>

                        </div>
                        <!--                分割线-->
                        <div class="rule3"></div>
                        <!--                循环列表下部分-->
                        <div class="listFloor">

                            <div class="createDiv">
                                <span  class="createMethodsDiv">申请日期 : </span>
                                <span class="createTimeDiv">{{item.billingDay.substring(0,10)}}</span>
                            <!--审核按钮-->
                            </div>
                            <div>
                                <button class="btn1" @click="finish(index)">完成</button>
                                
                            </div>
                                

                        </div>
                    </div>
                </div>
            </van-list>
            <common-empty v-if="isEmptyFlag"></common-empty>
            </van-pull-refresh>
        </div>
		
		<dial-mobile :ownerMobile="renterMobile ? renterMobile : '暂无' " :dialogTitle="dialogTitle" :mobileShow="isMobileShow1" @hideDialog="hideDialog1"></dial-mobile>
      <!-- <van-popup v-model="isShow" position="bottom">
        <van-picker show-toolbar :columns="typeList" @cancel="isShow = false" :default-index="number" value-key="dictionaryTitle" @confirm="changeType"/>
      </van-popup> -->
    </div>
</template>

<script>
    import { Button ,Search,DropdownMenu,DropdownItem,Divider,List,NavBar,Picker,
      Popup,PullRefresh } from 'vant';
	import dialMobile from '../../components/rongxun/rx-dialMobile/dialMobile'
    import {distributionListInit, queryBaseData,getStaffFuntionModelList,afterRentClean} from "../../getData/getData";
    import {getStaffId, responseUtil, checkAuthList, globaluserId} from "../../libs/rongxunUtil";
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
    export default {
        components: {
            [Button.name]:Button,
            [Search.name]:Search,
            [DropdownMenu.name]:DropdownMenu,
            [DropdownItem.name]:DropdownItem,
            [Divider.name]:Divider,
            [NavBar.name]: NavBar,
            [List.name]: List,
            rxNavBar,
			      dialMobile,
          [Picker.name]: Picker,
          [Popup.name]: Popup,
            [PullRefresh.name]: PullRefresh
        },
        data() {
            return {
                isLoading : false,
                //判断列表是否为空，是否显示空状态图
                isEmptyFlag:false,
                flag:false,
                page: 1,
                loading: false,
                finished: false,
                inputValue:'',
                number:1,
                type:'未审批',
                typeList:[{dictionaryValue: '',dictionaryTitle: '全部审批'}],
                isShow:false,
                name:"",
                value1:"3",
                searchDate:"",
                option: [

                ],
                datas:{
                    //每次3条
                    numberPage:'3',
                    approvalStatus:'0',
                    user_id:globaluserId(),
                    afterRentType:'0',
                },
                orders: [],
                authButtonsList:[],//权限列表
                renterMobile:'',
                isMobileShow1:false,
                dialogTitle:'联系租客',
            };
        },
        created() {
            this.getStaffFuntionModelList()
            if(JSON.parse(localStorage.getItem('inputValue'))){
                this.inputValue = JSON.parse(localStorage.getItem('inputValue'))
            }
        },

        methods:{
            onRefresh() {
                this.orders = []
                this.finished = false;
                this.page = 1
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.isLoading = false
                this.onLoad();
                responseUtil.alertMsg(this,"刷新成功")
            },
            //获取权限方法
            getStaffFuntionModelList () {
                var that = this
                let data = {}
                data.staff_id = getStaffId()
                data.menuName = 'distributionApplication_index'
                getStaffFuntionModelList(data).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.authButtonsList = response.data.data.data
                    })
                })
            },
            checkAuthList,

            //搜索
            onSearch(){
            },
            leftReturn(){
                this.$router.go(-1);
            },

			//拨打在租租客电话
			showMobile1(e){
			    let that = this;
			    that.renterMobile = e
			    console.log("1111")
			    that.isMobileShow1 = !that.isMobileShow1;
			},
			hideDialog1() {
			    let that = this;
			    that.isMobileShow1 = false;
			},
			
			
			//完成
			finish(index){
			    this.$router.push({
			        name:'addClearRetreatPhoto',
			        query:{
			            
			            afterId:this.orders[index].afterRent_id,
						      user_id:getStaffId(),
			        }
			    });
			},

            onLoad:function(){
	            var that = this
				      let initData = {}
				      initData.pageSize = '10'
	            //下拉刷新的页数
	            initData.currentPage = that.page+''
	            initData.houseGlu = that.inputValue
	            // initData.user_id = '100'
              initData.user_id = getStaffId()
              initData.checkStatus = '0'
	            //initData.type = "3"
	            afterRentClean(initData).then(function (response) {
	                responseUtil.dealResponse(that, response, () => {
	                    that.count =  response.data.data.sum[0].pending
	                    let list = response.data.data.data.result
	                    for(let i=0;i<list.length;i++){
	                        that.orders.push(list[i])
	                    }
	                    //加载状态结束
	                    that.loading = false;
	                    that.page++;
	                    //数据全部加载完成
	                    if (that.orders.length >= that.count) {
	                        that.finished = true;
	                       
	                    }
                      if (list.length == 0) {
                          that.finished = true;

                      }
	                })
	            })
	        },
            //搜索框查询
            goSearch(){
                this.orders=[]
                this.page = 1;
                if(this.inputValue!=''){
                    localStorage.setItem('inputValue',JSON.stringify(this.inputValue))
                }else{
                    localStorage.removeItem('inputValue')
                }
                this.finished = false;
                this.loading = true;
                this.onLoad()

            },
        }
    }
</script>

<style lang="less" scoped >
    .nav_bar_title{
        z-index: 99;
    }
    .head{
        width: 100%;
        position: fixed;
        z-index: 8;
        overflow: hidden;
        background: #f8f8f8;
    }
    .search{
        margin: 8px 20px 0 20px;
        height: 36px;
        border-radius: 30px;
        background-color: rgba(244, 244, 244, 1);
        display: flex;
        align-items: center;
    }
    .search-icon{
        width: 19px;
        height: 19px;
        margin-left: 9px;
        background-image: url("../../assets/images/search-icon.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    input{
        font-size: 14px;
        margin-left: 10px;
        background-color: rgba(244, 244, 244, 1);
        border: 0;
        width: 250px;
    }
    input::-webkit-input-placeholder{
        color: rgba(199, 199, 199, 1);
    }
    .types{
        width: 100%;
        height: 30px;
        display: flex;
    }
    .type{
        width:auto;
        height: 17px;
        line-height: 17px;
        text-align: left;
        color: #fe5e3a;
        margin: 15px 0 0 15px;
        color: rgba(255, 93, 59, 1);
        font-size: 13px;
        font-family: PingFangSC-Regular;
    }
    .small-icon{
        width: 8px;
        height: 8px;
        margin: 19px 0 0 5px;
        background-image: url("../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 90%;
    }
    .small-icon-rotate{
        width: 8px;
        height: 8px;
        margin: 18px 0 0 5px;
        background-image: url("../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 90%;
        transform:rotate(180deg);
    }
    .choose-select{
        position: fixed;
        z-index: 6;
        padding-top: 90px;
        width: 100%;
    }
    .selectList{
        width: 100%;
        height: auto;
        padding: 5px 0 14px 0;
        background-color: white;
    }
    .select{
        width: 100%;
        height: 35px;
        line-height: 30px;
        font-size: 14px;
        text-align: left;
        margin-left: 15px;
        color: #999999;
    }
    .selectActive{
        color: #fe5e3a;
    }
    .h-line{
        height: 1px;
        margin: 0 5px 0 10px;
        background-color: white;
    }

    /*    下拉菜单*/
    .downMenuDiv{
        width: 2.2rem;
    }

    .auditDiv{
        padding-top: 80px;
    }
    /*    循环列表DIV*/
    .stateList{
        border-radius: 0.3rem;
        width: 93%;
        margin-left: 0.35rem;
        position: relative;
        margin-bottom: 0.4rem;
        background-color: #ffffff;
        padding-bottom: 0.5rem;
    }
    .stateUnapproval,.statePassed,.stateRejected{
        height: 0.45rem;
        width: fit-content;
        padding: 0 0.13rem;
        border-radius: 0.2rem 0rem;
        font-size: 11px;
        text-align: center;
        color: #ffffff;
        line-height: 0.5rem;
    }
    /*列表状态样式 1是未审批*/
    .stateUnapproval{
        background: -webkit-linear-gradient(left,#FFC274,#FF5D3B);
    }
	.stateUnapproval2{
	    height: 0.45rem;
	    width: fit-content;
	    padding: 0 0.13rem;
	    border-radius: 0.2rem 0rem;
	    font-size: 11px;
	    text-align: center;
	    //color: #ffffff;
	    line-height: 0.5rem;
	}
    /*列表状态样式 2是已通过*/
    .statePassed{
        background: -webkit-linear-gradient(left,#66CB94,#28D3B0);
    }
    /*列表状态样式 3是已驳回*/
    .stateRejected{
        background: -webkit-linear-gradient(left,#E02020,#FF431B);
    }
    /*列表头部红色重点信息*/
    .headSpan{
        float: left;
        margin-right: 0.1rem;
        color: #FF5D3B;
    }
    /*列表头部小圆点*/
    .dot{
        border-radius: 50%;
        width: 0.01rem;
        height: 0.01rem;
        border: 0.05rem solid black;
        float: left;
        margin-top: 0.24rem;
        margin-right: 0.1rem;
    }
    /*列表头部信息*/
    .headInfo{
        margin-top: 0.4rem;
        margin-left: 0.4rem;
        font-size: 15px;
        font-weight: bold;
		width: 92%;
    }
    /*列表详情*/
    .listDetail{
        position: relative;
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .listDetail:after{
        content: "";
        display: block;
        clear: both;
    }
    /*    申请人信息DIV*/
    .proposerDiv{
        //margin-left: 0.5rem;
        font-size: 13px;
        color: #999999;
        float: left;
		text-align: center;
		width: 33.3%;
		
		
    }
    /*分割线1*/
    .rule1{
        position: absolute;
        left: 2.6rem;
        top: 0.1rem;
        border: 0.01rem solid #f7f7f7;
        height: 1rem;
        float: left;
    }
    /*分割线2*/
    .rule2{
        position: absolute;
        left: 5.5rem;
        top: 0.1rem;
        border: 0.01rem solid #f7f7f7;
        height: 1rem;
        width: 0.01rem;
        float: left;
    }
    /*装修预算和改造户型*/
    .budgetDiv{
        font-size: 13px;
        color: #999999;
        margin-left: 1.08rem;
        float: left;
    }
    .remouldDiv{
        font-size: 13px;
        color: #999999;
        margin-left: 1.28rem;
        float: left;
    }
    /*   装修预算 */
    .budgetDiv{
        width: 1.5rem;
        text-align: center;
    }
    /*    分割线2*/
    /* 申请人取值DIV*/
    .proposerValueDiv{
        margin-top: 0.3rem;
        font-size: 14px;
        color: #FF5D3B;
        height: 0.5rem;
        float: left;
        /*margin-left: 0.6rem;*/
        //width: 2rem;
        /*border: 1px solid red;*/
        text-align: center;
		width: 33.3%;
		
    }
    /*装修预算和改造户型取值*/
    .budgetValueDiv,.remouldValueDiv{
        font-size: 14px;
        color: #FF5D3B;
        margin-top: 0.3rem;
        float: left;
        //width: 2rem;
        /*border: 1px solid red;*/
        text-align: center;
		width: 33.3%;
		word-wrap:break-word;
    }
    // .budgetValueDiv{
    //     margin-left: 0.8rem;
    // }
    // .remouldValueDiv{
    //     margin-left: 1.3rem;
    // }
    /*    分割线3*/
    .rule3{
        border: 0.01rem solid #efefef;
        width: 100%;
        height: 0rem;
        margin-top: 0.1rem;
    }
    /*    申请原因*/
    .apply-result{
        margin-left: 0.5rem;
        margin-top: 0.3rem;
        line-height: 0.6rem;
        margin-bottom: 0.3rem;
        padding-right: 10px;
    }
    /*    尾部DIV*/
    .listFloor{
        margin-left: 0.5rem;
        margin-top: 0.35rem;
        height: 0.73rem;
        line-height: 0.73rem;
        display: flex;
        .createDiv{
            width: 70%;
        }
    }
    /*.listFloor :nth-of-type(1){*/
    /*    width: 70%;*/
    /*    border: 1px solid red;*/
    /*}*/


    /*    尾部 创建方式的createMethodsDiv*/
    .createMethodsDiv{
        font-size:13px ;
        color: #999999;
    }
    /*    尾部 创建时间的createTimeDiv*/
    .createTimeDiv{
        display: inline;
        font-size:13px ;
        margin-left: 0.2rem;
        color: #999999;
    }

    /*    下部按钮*/
    .btn1,.btn2{
        /*float: right;*/
        //margin-right: 0.7rem;
        height: 0.82rem;
        width: 2.3rem;
        border-radius: 0.2rem;
        border: 0rem solid transparent;
        box-shadow: none;
        line-height: 0.7rem;
        font-size: 13px;
    }
    /*按钮*/
    .btn1{
        background: -webkit-linear-gradient(left,#FFC274,#FF5D3B);
        color: white;
    }
    .btn2{
        padding: 0.01rem;
        border: 0.03rem solid transparent;
        background-image: linear-gradient(white,white),
        linear-gradient(to right ,#FFC274,#FF5D3B);
        background-clip: padding-box,border-box;
        background-origin: border-box;
        color: #FF5D3B;
    }
    .tel_IMg{
        width: 20px;
        height: 20px;
        /*margin-right: 20px;*/
        line-height: 20px;
		float: right;
    }
    .tel{
        width: 19px;
        height: 18px;
    }
    .position-span-right{
        color: black;
        font-size: 15px;
        width: 100%;
    }
</style>
